import React from "react";

import { Col, Container, Row } from "reactstrap";
import smallBoy from "../assets/img/small-boy.png";

function Privacy() {
  return (
    <div className="privacy-box">
      <Container>
        <Row>
          <Col md="6" className="order-md-1 order-2">
            <div className="content">
              <p className="privacy-text text-justify">
                Wij zijn het JijBentBelangrijk.nl onderzoekspanel. Het panel
                bestaat al sinds 2007, maar is sinds eind 2017 bezig om te
                groeien. Als lid van het Jijbentbelangrijk.nl panel sta jij
                centraal. Wat voor jou belangrijk is, is heel persoonlijk.
                Iedereen heeft zijn of haar eigen doelen. Jij bent belangrijk en
                we gaan jou helpen om deze doelen te behalen.
              </p>
              <p className="privacy-text text-justify">
                Wat je doelen ook zijn, niemand bereikt doelen zonder te
                trainen. Dus hoe meer meters je maakt in het
                Jijbentbelangrijk.nl panel, hoe meer geld je kan verdienen om je
                doelen te verwezenlijken. Jouw mening is namelijk geld waard bij
                ons.
              </p>
              <a
                href="https://cdn1.collecthor.nl/organisation8/public/JBB/Algemene voorwaarden Jijbentbelangrijk.nl 2019-10.pdf"
                target="_blank"
                className="footer-underline"
              >
                Algemene Voorwaarden
              </a>
              <br />
              <a
                href="https://cdn1.collecthor.nl/organisation8/public/JBB/PrivacyverklaringJijbentbelangrijk.nl2018.pdf"
                target="_blank"
                className="footer-underline"
              >
                Privacy Statement
              </a>
            </div>
          </Col>
          <Col md="6" className="order-md-2 order-1">
            <div className="image">
              <img src={smallBoy} alt="smallBoy" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Privacy;
