import { Link } from 'react-router-dom';
import Topbar from '../components/Topbar';
import BannerBox from '../components/BannerBox';
import FormBox from '../components/FormBox';
import StepBox from '../components/StepBox';
import Privacy from '../components/PrivacyBox';
import HeroSection from '../components/HeroSection';

const AdminView = props => {
	return (
		<>
			{/* <h2>Main View</h2> */}
			{/* <Link to='/auth'>Auth View</Link> */}
			<div id='wapper'>
				<div id='mainCntr'>
					{/* <Topbar /> */}
					{/* <BannerBox /> */}
					{/* <FormBox /> */}
					<HeroSection/>
					<StepBox />
					<Privacy />
				</div>
			</div>
		</>
	);
};

export default AdminView;
