import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import RightArrow from "../assets/img/right-arrow.png";
import leftArrow from "../assets/img/left-arrow.png";
import StepImage from "../assets/img/step-img.png";
import StepImage1 from "../assets/img/step-img1.png";
import StepImage2 from "../assets/img/step-img2.png";
import StepImage3 from "../assets/img/step-img3.png";
import fijlogo from "../assets/img/fijlogo.png";
function stepBox() {
  return (
    <>
      <div className="bg-theme-light">
        <Container className="stepbox ">
          {/* <div className="stepbox__title  d-flex align-items-center justify-content-center text-center">
            <div className="stepbox__title-space">
              <span>Jijbentbelangrijk.nl</span>
              HOE WERKT HET?
            </div>
          </div> */}
          <Row className="d-lg-none d-block">
            <Col lg={6} className="position-relative">
              <img src={fijlogo} alt="pic" className="img-fluid w-25" />
            </Col>
            <Col md={3}>
              <div className="stepbox__title  d-flex align-items-center justify-content-center text-center  ">
                <div className="stepbox__title-step">
                  <span>jijbentbelangrijk.nl</span>
                  HOE WERKT HET?
                </div>
              </div>
              <div className="w-25 mx-5  my-auto">
                <img
                  src={RightArrow}
                  alt="left"
                  width={70}
                  className="img-fluid float-md-right  mx-5 position-absolute"
                />
              </div>
            </Col>
          </Row>
          <div className="stepbox__box">
            <Row className="pt-5">
              <Col md="6" className="position-relative">
                {/* <div className="stepbox__box-content text-left "> */}
                <h3 className="mt-5">Stap 1. Meld je hier aan </h3>
                <p className="subtext">
                  Maak in bovenstaand formulier een account aan.
                  {/* Om te starten heb je uiteraard eerst een account nodig.{" "}
                    <a href="#form">
                      <u>Meld je hierboven dus direct aan!</u>
                    </a> */}
                </p>
                <div className="w-25 float-md-right d-md-block d-none my-auto d-none">
                  <img
                    src={RightArrow}
                    alt="left"
                    width={70}
                    className="img-fluid  position-absolute"
                  />
                </div>
                {/* </div> */}
              </Col>
              <Col md="6" className="mb-5">
                <img
                  src={StepImage}
                  alt="StepImage"
                  width={130}
                  className="img-fluid  order-md-2 mx-auto my-3 mx-md-0 my-md-1"
                />
              </Col>
              <Col
                sm="12"
                className="order-md-3 order-3 d-md-none d-block text-center"
              >
                <div className="left-arrow">
                  <img src={leftArrow} alt="left" className=" " />
                </div>
              </Col>
            </Row>
            <Row className="">
              <Col md="6" className="order-1">
                <div className="image d-flex">
                  <img
                    src={StepImage1}
                    alt="StepImage1"
                    width={130}
                    className="img-fluid mx-auto my-3 mx-md-0 my-md-1"
                  />

                  {/* <div className="right-arrow d-md-block d-none my-auto">
                    <img
                      src={RightArrow}
                      alt="rightArrow"
                      className="img-fluid"
                    />
                  </div> */}
                </div>
              </Col>
              <Col md="6" className="order-2 position-relative">
                <h3>Stap 2. Bevestig je inschrijving</h3>
                <p className="subtext">
                  Je ontvangt een e-mail met jouw bevestiginglink.
                </p>
                <div className="left-arrow d-md-block d-none my-auto order-1">
                  <img
                    src={leftArrow}
                    alt="left"
                    width={55}
                    className="img-fluid  position-absolute"
                  />
                </div>
              </Col>
              <Col
                sm="12"
                className="order-md-3 order-3 d-md-none d-block text-center"
              >
                <div className="right-arrow">
                  <img src={RightArrow} alt="right" className="" />
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col md="6" className="order-md-1 order-2">
                <div className="content mt-5">
                  <h3>Stap 3. Start met bijverdienen</h3>
                  <p className="subtext">
                    Op jijbentbelangrijk.nl kan je meteen beginnen met jouw
                    eerste meters maken. Hoe meer meters, hoe meer je uitbetaald
                    krijgt!
                  </p>
                </div>
              </Col>
              <Col md="6" className="order-md-1 order-1">
                <div className="image thirld d-flex align-items-center justify-content-center">
                  <img
                    src={StepImage2}
                    width={130}
                    alt="StepImage2"
                    className="order-2 mx-auto my-3 mx-md-0 my-md-1"
                  />
                </div>
              </Col>
              <Col
                sm="12"
                className=" order-md-3 order-3 d-md-none d-block text-center"
              >
                {/* <div className="left-arrow">
                  <img src={leftArrow} alt="left" className="" />
                </div> */}
              </Col>
            </Row>
            {/* <Row className="align-items-center">
              <Col md="6" className="order-1">
                <div className="image d-flex">
                  <img
                    src={StepImage3}
                    alt="StepImage3"
                    className="img-fluid  mx-auto my-3 mx-md-0 my-md-1"
                  />
                  <div className="right-arrow d-md-block d-none my-auto">
                    <img
                      src={RightArrow}
                      alt="RightArrow"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </Col>
              <Col md="6" className="order-2">
                <div className="content text-md-end">
                  <h3>
                    Stap 4. Meters maken is geld verdienen! Geef je mening!
                  </h3>
                  <p>
                    Hoe meer vragenlijsten je invult, hoe meer meters je maakt.
                    Elke 1.000 meter (1 km) die je loopt is €1,- waard. Hoe meer
                    meters je spaart, hoe groter je winst. Hoe meer je traint,
                    hoe beter je wordt en hoe groter de prestatie is die je neer
                    kan zetten. Het trainen voor een marathon word dus hoger
                    beloond!
                  </p>
                </div>
              </Col>
            </Row> */}
            <div className="stepbox__join text-center">
              <Button
                color="primary"
                className="stepbox__join-btn"
                onClick={() => window.scrollTo(0, 315)}
              >
                GRATIS AANMELDEN
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default stepBox;
