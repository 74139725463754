import axios from "axios";
const baseDomain = "https://leadgen.deambassadeur.nu/api/sponsors/2042";
const baseURL = `${baseDomain}`;
let axiosObj;
axiosObj = axios.create({
  baseURL,
  headers: {
    // "Content-Type": "application/json",
    Authorization: `Basic MTg1OmFiODIyMWQ0YTMxNzBkODk1NDI4ODA0NTlhYmY3OTgxN2FlMzY3YzI=`,
  },
});

export default axiosObj;
