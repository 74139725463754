import { RepositoryFactory } from "../../repository/RepositoryFactory";
var LeadRepo = RepositoryFactory.get("newLead");

export const createNewLead =
  (data, onComplete = () => {}) =>
  async (dispatch) => {
    let { bootCheck, ...payload } = data;
    await dispatch(createLoader(true));
    if (data.bootCheck) {
      await dispatch(createLoader(false));
      dispatch({
        type: "CREATE_RESPONSE",
        payload: "data",
      });
    } else {
      try {
        let { data } = await LeadRepo.createNewLead(payload);
        if (data) {
          dispatch({
            type: "CREATE_RESPONSE",
            payload: data,
          });
          console.log(data, "data");
          await dispatch(createLoader(false));
        } else {
          await dispatch(createLoader(false));
        }
      } catch (e) {
        if (e?.response?.data?.error === "Conflict - duplicate") {
          // console.log("dublicates");
          onComplete();
        } else {
          // alert(e?.response?.data?.error);
          let error = e.response.data.error.match(/'([^']+)'/)[0].slice(1, -1);
          dispatch({
            type: "ERROR_RESPONSE",
            payload: { [error]: true },
          });
        }
        await dispatch(createLoader(false));
      }
    }
  };
export const setErrorResponse = () => async (dispatch) => {
  dispatch({
    type: "ERROR_RESPONSE",
    payload: null,
  });
};
export const createLoader = (val) => async (dispatch) => {
  dispatch({
    type: "CREATE_LOADER",
    payload: val,
  });
};
