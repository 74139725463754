import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  Button,
  Form,
  Container,
  Row,
} from "reactstrap";

function ThankYouForm() {
  const { create_response } = useSelector((state) => state.street);
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <Form className="my-auto  d-flex p-4 bg-white rounded form-height justify-content-center text-center align-items-center">
        <Container className="text-center">
          <p className="text-blue fs--20 justify-content-center font-family">
            Bedankt voor je aanvraag!
          </p>
          <hr />
          <div className="text-left">
            <h3 className="text-blue">
              Bedankt voor je aanmelding. Controleer snel je inbox om je e-mail
              adres te bevestigen en zo snel mogelijk te starten met verdienen!
            </h3>
            <h3 className="text-blue">Met vriendelijke groet,</h3>
            <h3 className="text-blue">Jijbentbelangrijk</h3>
          </div>
          <div className="text-left">
            <img
							referrerpolicy='no-referrer-when-downgrade'
							src={`https://republish.aanvraaginformatie.be/m/5556/209b42e4689a/?event=6498&unique_conversion_id=${create_response?.profile?.id}`}
							style={{
								width: '50px',
								height: '50px',
								border: '0px',
							}}
						/>
          </div>
        </Container>
      </Form>
    </>
  );
}

export default ThankYouForm;
