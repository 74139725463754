import React from "react";
import { Col, Container, Row } from "reactstrap";
import FormBox from "./FormBox";
import girlPic from "../assets/img/girl-img.png";
import fijlogo from "../assets/img/fijlogo.png";
import RightArrow from "../assets/img/right-arrow.png";
export default function HeroSection() {
  return (
    <Container fluid className="hero_section">
      <Container>
        <Row className="pt-0 pt-lg-5">
          <Col md={6} className="d-flex flex-column justify-content-between ">
            <Row>
              <Col lg={6} md={12} className="d-flex flex-column align-items-center">
                <div className="text-white mt-5">
                  <h2 className="h3">
                    Welkom bij het leukste panel van Nederland!
                  </h2>
                  <p className="p">
                    Maandelijks tot €100 verdienen met 
                    vragenlijsten invullen 
                  </p>
                </div>
              </Col>
              <Col lg={6} md={12} className="position-relative  d-lg-block d-none mt-3">
                <div class="girl-img">
                  <img src={girlPic} alt="pic" className="img-fluid girl-shake" />
                </div>
              </Col>
            </Row>
            <Row className=" d-lg-block d-none">
              <Col lg={6} className="">
                <img src={fijlogo} alt="pic" className="img-fluid fijlogo w-50" />
              </Col>
              <Col lg={6}>
                <div className="stepbox__title step-top  d-flex align-items-center justify-content-center text-center  ">
                  <div className="stepbox__title-space">
                    <span>jijbentbelangrijk.nl</span>
                    HOE WERKT HET?
                  </div>
                </div>
                <div className="w-25 mx-5 d-md-block d-none my-auto d-none">
                  <img src={RightArrow} alt="left" width={70} className="img-fluid  position-absolute" />
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={6} md={12} className="d-flex flex-column align-items-center ">
            <FormBox />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
