import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import girlPic from "../assets/img/girl-img.png";
import boxPic from "../assets/img/boy-img.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as moment from "moment";
import { createNewLead, setErrorResponse } from "../store/actions/StreetAction";
import ThankYouForm from "./ThankYouForm";
import ChildrenThankYouForm from "./ChildrenThanYouForm";

const FormBox = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [email, setEmail] = useState("");
  const [allDays, setAllDays] = useState([]);
  const [allYear, setAllYear] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [bootCheck, setBootCheck] = useState(false);
  const [allMonths, setAllMonths] = useState([
    {
      label: "jan",
      id: "01",
    },
    {
      label: "feb",
      id: "02",
    },
    {
      label: "maart",
      id: "03",
    },
    {
      label: "april",
      id: "04",
    },
    {
      label: "mei",
      id: "05",
    },
    {
      label: "juni",
      id: "06",
    },
    {
      label: "juli",
      id: "07",
    },
    {
      label: "aug",
      id: "08",
    },
    {
      label: "sep",
      id: "09",
    },
    {
      label: "okt",
      id: "10",
    },
    {
      label: "nov",
      id: "11",
    },
    {
      label: "dec",
      id: "12",
    },
  ]);
  const [isChecked, setIsChecked] = useState(false);
  const [siteSubId, setSiteSubId] = useState("");
  const [publisherId, setPublisherId] = useState("");
  const [gender, setGender] = useState("");
  const [isChild, setIsChild] = useState(false);
  const [childForm, setChildForm] = useState(false);
  const [calDate, setCalDate] = useState("");
  const [dublicatesConflit, setDublicateConflit] = useState(false);

  const dispatch = useDispatch();
  let params = useParams();
  const history = useHistory();

  const checkNameRegex = (val) => {
    let validName = /^([a-zA-Z ]){1,30}$/;
    if (val != "") {
      if (validName.test(val)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const checkLastName = (val) => {
    let validName = /^([a-zA-Z ]){1,30}$/;
    if (val != "") {
      if (validName.test(val)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const checkEmailRegex = (val) => {
    let validName = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (val != "") {
      if (validName.test(val)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const checkZipRegex = (val) => {
    let validName = /^([0-9]{4}\s?[a-zA-Z]{2})$/;
    if (val != "") {
      if (validName.test(val)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  // const HandleallYear = e => {
  // 	e.preventDefault();
  // 	let yearArray = [];
  // 	for (let i = 1970; i <= 2023; i++) {
  // 		let yearObj = {
  // 			year: i,
  // 		};
  // 		yearArray.push(yearObj);
  // 		setAllYear(yearArray);
  // 	}
  // };

  // const HandleSelectedDay = e => {
  // 	e.preventDefault();
  // 	// let dayArray = [];
  // 	// for (let i = 1; i <= 31; i++) {
  // 	// 	let dayObj = {
  // 	// 		day: i,
  // 	// 	};
  // 	// 	dayArray.push(dayObj);
  // 	// }
  // 	const dayArray = Array.from({ length: 30 }, (_, i) => i + 1);
  // 	setAllDays(dayArray);
  // };

  useEffect(() => {
    let yearArray = [];
    for (let i = 2010; i > 1899; i--) {
      let yearObj = {
        year: i,
      };
      yearArray.push(yearObj);
      setAllYear(yearArray);
    }
  }, []);
  useEffect(() => {
    const dayArray = Array.from({ length: 31 }, (_, i) => i + 1);
    setAllDays(dayArray);
  }, []);

  useEffect(() => {
    const query = history.location.search;
    // console.log(query, "query");
    if (query) {
      const site_subid = new URLSearchParams(query).get("oa_id");
      const publisher_id = new URLSearchParams(query).get("site");

      setSiteSubId(site_subid);

      setPublisherId(publisher_id);
      //   console.log(site_subid, "site_subid");
      //   console.log(publisher_id, "publisher_id");
    }
  }, []);

  useEffect(() => {
    if (selectedYear !== "" && selectedMonth !== "" && selectedDay !== "") {
      const DateOfBirth = moment({
        year: selectedYear,
        month: selectedMonth - 1,
        day: selectedDay,
      }).format("YYYY-MM-DD");
      //  #################################### //
      const currentYear = moment().diff(DateOfBirth, "years");
      let temp = parseInt(currentYear);
      // console.log(temp, "temp");
      if (temp >= 55 || temp < 18) {
        // console.log("true");
        setIsChild(true);
      } else {
        // console.log("false");
        setCalDate(DateOfBirth);
      }
    }
  }, [selectedYear || selectedMonth || selectedDay]);

  const HandleSubmittedData = (e) => {
    e.preventDefault();
    console.log(gender, "gender");

    if (
      checkNameRegex(firstName) === false &&
      checkLastName(lastName) === false &&
      checkEmailRegex(email) === false &&
      checkZipRegex(zipCode) === false &&
      gender != ""
    ) {
      //
      console.log(gender, "gender");
      const data = {
        firstname: firstName,
        lastname: lastName,
        email,
        zip: zipCode,
        gender: gender,
        date_of_birth: calDate,
        answers: ["3842"],
        language: "nl_NL",
        site_custom_url: "https://jijbentbelangrijk.gratismeedoen.nl",
        site_custom_name: "jijbentbelangrijk",
        site_subid: siteSubId,
        publisher_id: publisherId,
        bootCheck: bootCheck,
      };
      if (isChild === false) {
        dispatch(
          createNewLead(data, () => {
            setDublicateConflit(true);
          })
        );
      } else {
        setChildForm(true);
        // alert("Sorry your age is not valid!");
        // console.log("working fine");
      }
    } else {
      console.log(gender, "gender");
      alert("Please Fill the complete form data and accurate!");
    }
  };
  // console.log(dublicatesConflit, "dublicateConflit");

  const { create_response, loading, error_response } = useSelector(
    (state) => state.street
  );

  // console.log("create_response", error_response);

  return (
    // <div className="form-box" id="form">
    //   <Container>
    <div>
      <div className="form-box__bg">
        {(create_response && childForm == false) ||
        error_response?.date_of_birth == true ? (
          <>
            <ThankYouForm />
          </>
        ) : childForm == true || dublicatesConflit == true ? (
          <>
            <ChildrenThankYouForm />
          </>
        ) : (
          <div className="form-box__bg__box">
            <div class="text-center">
              <h3>
                Meld je vandaag aan en start meteen met verdienen:
                {/* <span>
                      en <br />
                      verdien meteen
                    </span>{" "}
                    je eerste euro! */}
              </h3>
              {/* <h4>en verdien gelijk</h4> */}
            </div>

            <Form action="#" className="mt-3" onSubmit={HandleSubmittedData}>
              {/* /End/ */}
              <div className="form-box__bg__box__row">
                <Row className="form-group mb-md-2">
                  {/* <FormGroup tag="fieldset"> */}
                  {/* <legend>Geslacht</legend> */}
                  <Col xs={6} className="px-0">
                    <FormGroup check>
                      <Label
                        check
                        id="male"
                        className={`ml-2  form-box__bg__box__row-gender ${
                          gender == "male"
                            ? "top-radio-labels-after"
                            : "top-radio-labels"
                        }`}
                      >
                        <Input
                          name="gender"
                          type="radio"
                          id="male"
                          value="male"
                          checked={gender === "male"}
                          className="ml-1 top-filled"
                          onChange={(e) => setGender(e.target.value)}
                        />{" "}
                        Meneer
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col xs={6} className="px-0">
                    <FormGroup check>
                      <Label
                        className={`ml-2  form-box__bg__box__row-gender ${
                          gender == "female"
                            ? "top-radio-labels-after"
                            : "top-radio-labels"
                        }`}
                        check
                        required
                      >
                        <Input
                          name="gender"
                          type="radio"
                          className="ml-1 top-filled"
                          value="female"
                          checked={gender === "female"}
                          onChange={(e) => setGender(e.target.value)}
                        />{" "}
                        Mevrouw
                      </Label>
                    </FormGroup>
                  </Col>
                  {/* </FormGroup> */}
                </Row>
                <Row className="mt-1 mb-md-2">
                  {" "}
                  <Col md={6} className="px-0">
                    <FormGroup>
                      <Label className="mb-0 top-labels ">Voornaam</Label>
                      <Input
                        type="text"
                        className={`form-control top-filled ${
                          error_response?.firstname == true && "border-danger"
                        }`}
                        placeholder="bv. Pieter"
                        invalid={
                          checkNameRegex(firstName) || error_response?.firstname
                        }
                        onChange={(e) => {
                          setFirstName(e.target.value);
                          error_response?.firstname &&
                            dispatch(setErrorResponse());
                        }}
                        required
                      />
                      <FormFeedback>Voornaam niet correct</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col className="px-0">
                    <FormGroup>
                      <Label className="mb-0 top-labels ">Achternaam</Label>
                      <Input
                        type="text"
                        className={`form-control top-filled ${
                          error_response?.lastname == true && "border-danger"
                        }`}
                        placeholder="bv. de Jong"
                        invalid={
                          checkLastName(lastName) || error_response?.lastname
                        }
                        onChange={(e) => {
                          setLastName(e.target.value);
                          error_response?.lastname &&
                            dispatch(setErrorResponse());
                        }}
                        required
                      />
                      <FormFeedback>Achternaam niet correct</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mt-1 mb-md-2">
                  {" "}
                  <Col md={12} className="px-0">
                    <FormGroup>
                      <Label className="mb-0 top-labels">E-mailadres</Label>

                      <Input
                        type="email"
                        className={`form-control top-filled ${
                          error_response?.email == true && "border-danger"
                        }`}
                        placeholder="bv. piet@dejong.nl"
                        invalid={
                          checkEmailRegex(email) || error_response?.email
                        }
                        onChange={(e) => {
                          setEmail(e.target.value);
                          error_response?.email && dispatch(setErrorResponse());
                        }}
                        required
                      />
                      <FormFeedback>E-mailadres niet correct</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mt-1 mb-md-2">
                  <Col md={12} className="px-0">
                    <FormGroup>
                      <Label className="mb-0 top-labels">Postcode</Label>

                      <Input
                        type="text"
                        className={`form-control top-filled ${
                          error_response?.zip == true && "border-danger"
                        }`}
                        placeholder="bv. 2008AB"
                        invalid={checkZipRegex(zipCode) || error_response?.zip}
                        onChange={(e) => {
                          setZipCode(e.target.value);
                          error_response?.zip && dispatch(setErrorResponse());
                        }}
                        required
                      />
                      <FormFeedback>Postcode niet correct</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mt-1 mb-md-2">
                  <Col xs={4} className="px-0">
                    <FormGroup>
                      <Label className="mb-0 top-labels">Geboortedatum</Label>

                      <select
                        // onClick={HandleSelectedDay}
                        className={`${
                          selectedDay == "" ? "custom-before" : "custom-select"
                        }`}
                        // data-placeholder='Dag'
                        onChange={(e) => setSelectedDay(e.target.value)}
                        required
                      >
                        <option value="" disabled selected>
                          Dag
                        </option>
                        {allDays?.map((data, index) => {
                          return (
                            <>
                              <option key={index}>{data}</option>
                            </>
                          );
                        })}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col xs={4} className="px-0">
                    <FormGroup className="">
                      <Label className="mb-0">&nbsp;</Label>

                      <select
                        className={`${
                          selectedMonth == ""
                            ? "custom-before"
                            : "custom-select"
                        }`}
                        onChange={(e) => setSelectedMonth(e.target.value)}
                        required
                      >
                        <option value="" disabled selected>
                          Maand
                        </option>

                        {allMonths?.map((data, index) => {
                          return (
                            <>
                              <option key={index} value={data?.id}>
                                {data?.label}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </FormGroup>
                  </Col>

                  <Col xs={4} className="px-0">
                    <FormGroup className="">
                      <Label className="mb-0">&nbsp;</Label>

                      <select
                        className={`${
                          selectedYear == "" ? "custom-before" : "custom-select"
                        }`}
                        // onClick={HandleallYear}
                        onChange={(e) => {
                          setSelectedYear(e.target.value);
                        }}
                        required
                      >
                        <option value="" disabled selected>
                          Jaar
                        </option>
                        {allYear?.map((data, index) => {
                          return <option key={index}>{data?.year}</option>;
                        })}
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col
                    sm="12"
                    className="form-group form-box__bg__box__row-check "
                  >
                    <Label>
                      <Input
                        type="checkbox"
                        className="ml-1 top-filled"
                        checked={isChecked}
                        onChange={(e) => setIsChecked(e.target.checked)}
                        required
                      />

                      <span className="text-form-text">
                        Ja, ik ga akkoord met het{" "}
                        <a
                          href="https://cdn1.collecthor.nl/organisation8/public/JBB/PrivacyverklaringJijbentbelangrijk.nl2018.pdf"
                          target="_blank"
                          className="text-form-text"
                        >
                          privacybeleid
                        </a>{" "}
                        en de{" "}
                        <a
                          href="https://cdn1.collecthor.nl/organisation8/public/JBB/Algemene voorwaarden Jijbentbelangrijk.nl 2019-10.pdf"
                          target="_blank"
                          className="text-form-text"
                        >
                          algemene voorwaarden
                        </a>{" "}
                        van Jijbentbelangrijk.nl
                      </span>
                    </Label>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col sm="12" className="form-group text-center">
                    <Button
                      type="submit"
                      className="w-100 align-items-center form-button"
                    >
                      {loading ? (
                        <Spinner
                          color="white"
                          size="large"
                          className="d-block text-center mx-auto my-auto"
                        />
                      ) : (
                        "GRATIS AANMELDEN"
                      )}
                    </Button>
                    <Input
                      type="checkbox"
                      value={bootCheck}
                      onChange={() => setBootCheck(!bootCheck)}
                      style={{ opacity: "0.001" }}
                    />
                    <span className="mt-2 text-form-text">
                      En start direct met geld verdienen!
                    </span>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        )}
      </div>
      {/* images */}
      {/* <div class="girl-img d-md-block d-none">
            <img src={girlPic} alt="pic" />
          </div> */}
      {/* <div class="boy-img d-md-block d-none">
            <img src={boxPic} alt="pic" />
          </div> */}
    </div>
    //   </Container>
    // </div>
  );
};

export default FormBox;
