// Root Reducer

import { combineReducers } from 'redux';
import authUserReducer from './authUser';
import StreetReducer from './StreetReducer';

export let rootReducer = combineReducers({
	authUser: authUserReducer,
	street: StreetReducer,
});

export default rootReducer;
